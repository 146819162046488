body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: unset !important;
  height: 100%;
  width: 100%;
}

/* .leaflet-container {
  height: 100%;
  width: 100%;
} */


html.login-bg{
  height: 100%;
  width: 100%;
  /* https://www.svgbackgrounds.com/#scattered-forcefields */

  background-color: rgb(0, 187, 119) !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='120' height='120' viewBox='0 0 120 120'%3E%3Cpolygon  fill='%23000' fill-opacity='.1' points='120 0 120 60 90 30 60 0 0 0 0 0 60 60 0 120 60 120 90 90 120 60 120 0'/%3E%3C/svg%3E");
  background-attachment: scroll;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
}

html.home-bg{
  height: 100%;
  width: 100%;
  /* https://www.svgbackgrounds.com/#scattered-forcefields */

  background-color: unset !important;
  background-image: unset;
  background-attachment: scroll;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
}

#root {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}


a:link {
	text-decoration: none;
}
a:hover {
	color: black;
	font-family: arial;
	text-decoration: none;
}
a:visited {
	color: black;
	font-family: arial;
	text-decoration: none;
}
a:active {
    color: black;
}
a:link, a:visited, a:active {
    color: inherit;
}
* {
	outline: none !important;
}
*:focus {
	outline: none !important;
}
textarea:focus,
input:focus {
	outline: none !important;
}
a {
	text-decoration: none !important;
	outline: none !important;
	color: black;
}
